import { Card, Divider, Descriptions, Button } from 'antd';
import FooterSteps from './shared/FooterSteps';
import HeaderSteps from './shared/HeaderSteps';
import InfoEnvio from './shared/InfoEnvio';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ErrorPage from './pages/ErrorPage';
import NotFoundPage from './pages/NotFoundPage';
import LoadingPage from './pages/LoadingPage';
import { Link } from 'react-router-dom';

const StatusDetailPage = () => {

  let { serviceId, qr } = useParams();
  const [data, setData] = useState(null);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ENDPOINT}/servicio/get/${serviceId}/${qr}`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return Promise.reject(response.status)
        }
      })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        if (error === 404) {
          setNotFound(true);
        } else {
          setError(true);
        }
        setLoading(false);
      });
  }, [serviceId, qr]);

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      {data == null ? null :
        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <HeaderSteps data={data} />
          <Divider />
          <InfoEnvio data={data} />
          <Divider />
          <Descriptions title="Origen" size={'default'} colon={false} layout="vertical">
            <Descriptions.Item span={2} key={"Remitente"} label="Remitente">{data.remitente}</Descriptions.Item>
            <Descriptions.Item key={"Sucursal Origen"} label="Sucursal Origen">{data.sucursalOrigen}</Descriptions.Item>
            <Descriptions.Item key={"Dirección Origen"} label="Dirección Origen">{data.direccionOrigen}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="Destino" size={'default'} colon={false} layout="vertical">
            <Descriptions.Item span={2} key={"Destinatario"} label="Destinatario">{data.destinatario}</Descriptions.Item>
            <Descriptions.Item key={"Sucursal Destino"} label="Sucursal Destino">{data.sucursalDestino}</Descriptions.Item>
            <Descriptions.Item key={"Dirección Destino"} label="Dirección Destino">{data.direccionDestino}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <FooterSteps data={data} />
          <Divider />
          <Link to="/"><Button type="primary">Volver</Button></Link>
        </Card>
      }
      {isError ? <ErrorPage /> : null}
      {isNotFound ? <NotFoundPage /> : null}
    </>
  );
};

export default StatusDetailPage;

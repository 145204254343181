import React from 'react';
import logo from '../assets/img/logo.svg';
import { Link } from 'react-router-dom';
import { Layout, Typography, Row, Col } from 'antd';
const { Header, Footer, Content } = Layout;
const { Paragraph } = Typography;
const AppLayout = ({ children }) =>
(
    <Layout>
        <Header align="center" className="bg-1" style={{ height: 150, paddingTop: 20 }}>
            <Link to="/">
                <img src={logo} alt="Soserval" height="100" />
            </Link>
        </Header>
        <Content className="bg-0 content">
            <Row>
                <Col sm={2} lg={4} span={4} />
                <Col sm={20} lg={16} span={16}>
                    {children}
                </Col>
                <Col sm={2} lg={4} span={4} />
            </Row>
        </Content>
        <Footer>
            <Paragraph style={{ marginBottom: 0 }} align="center">
                Desarrollado por Insacom.
            </Paragraph>
        </Footer>
    </Layout>
)

export default AppLayout;

import { Typography, Steps } from 'antd';

const { Step } = Steps;

const FooterSteps = (props) => {

    let eventos = props.data.hitos.map((event, idx) => <Step key={idx} title={event.nombre} description={event.fecha} />);
    return (
        <>
            <Typography.Title level={5}>Últimas Actualizaciones</Typography.Title>
            <Steps progressDot current={0} direction="vertical">
                {eventos}
            </Steps>
        </>
    );
};

export default FooterSteps;

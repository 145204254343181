import React, { useState } from 'react';
import truck from '../assets/img/truck.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import { Row, Col, Input, Typography } from 'antd';
const { Title, Paragraph } = Typography;
const { Search } = Input;

const HomePage = () => {
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const recaptchaRef = React.createRef();

  const onChange = () => {
    console.log('recaptcha change');
  };

  const onSearch = async value => {
    setLoading(true);
    setError(null);
    if (value === '' || value === null) {
      setError('Debe ingresar un Número de Seguimiento');
      setLoading(false);
      return;
    }
    await recaptchaRef.current.executeAsync();
    history.push('/status/' + value);
  };

  return (
    <>
      <Title style={{ marginTop: 100 }} align="center" level={2}>
        Bienvenido a Soserval
      </Title>
      <Paragraph align="center">
        Ingrese el número de seguimiento para ver el estado de su envío
      </Paragraph>
      <Paragraph align="center">
        <img src={truck} alt="Soserval" height="140" />
      </Paragraph>
      <Row>
        <Col span={24}>
          <Search
            size="large"
            disabled={isLoading}
            loading={isLoading}
            onSearch={onSearch}
            maxLength={30}
            enterButton
            placeholder="Ingresar Número de Seguimiento"
          />
          {error ? (
            <Paragraph style={{ marginTop: 10 }} type={'danger'}>
              {error}
            </Paragraph>
          ) : (
            ``
          )}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC}
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default HomePage;

import { Button, Card, Divider } from 'antd';
import FooterSteps from './shared/FooterSteps';
import HeaderSteps from './shared/HeaderSteps';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ErrorPage from './pages/ErrorPage';
import NotFoundPage from './pages/NotFoundPage';
import LoadingPage from './pages/LoadingPage';
import InfoEnvioPublico from './shared/InfoEnvioPublico';
import { Link } from 'react-router-dom';

const StatusPage = () => {

  let { qr } = useParams();
  const [data, setData] = useState(null);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ENDPOINT}/servicio/qr/${qr}`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return Promise.reject(response.status)
        }
      })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        if (error === 404) {
          setNotFound(true);
        } else {
          setError(true);
        }
        setLoading(false);
      });
  }, [qr]);

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      {data == null ? null :
        <>
          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <HeaderSteps data={data} />
            <Divider />
            <InfoEnvioPublico data={data} />
            <Divider />
            <FooterSteps data={data} />
            <Divider />
            <Link to="/"><Button type="primary">Volver</Button></Link>
          </Card>
        </>
      }
      {isError ? <ErrorPage /> : null}
      {isNotFound ? <NotFoundPage /> : null}
    </>
  );
};

export default StatusPage;


import { Spin } from 'antd';

const LoadingPage = () =>
  <>
    <div style={{ width: '100%', height: '100px', textAlign: 'center', paddingTop: '40px' }}>
      <Spin size='large' tip='Cargando...' />
    </div>
  </>

export default LoadingPage;

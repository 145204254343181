import { Descriptions } from 'antd';

const InfoEnvio = (props) =>
    <>
        <Descriptions size={'small'} colon={false} title="Información del Envío" layout="vertical">
            <Descriptions.Item key="Tipo Envío" label="Tipo Envío" span={1}>
                {props.data.tipoServicio.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item key="Vía Ingreso" label="Vía Ingreso" span={2}>
                {props.data.viaIngreso}
            </Descriptions.Item>
            <Descriptions.Item key="Código" label="Código" span={2}>
                {props.data.codigoQr}
            </Descriptions.Item>
        </Descriptions>
    </>

export default InfoEnvio;
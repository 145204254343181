
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const ErrorPage = () =>
  <>
    <Result status={500} title={'Oops!'} subTitle='Ha ocurrido un error al consultar por el envío. Inténtelo denuevo más tarde' extra={<Link to="/"><Button type="primary">Volver</Button></Link>} />
  </>

export default ErrorPage;

import { Typography, Result, Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import RetiradoIcon from '../../icons/RetiradoIcon';
import IngresadoIcon from '../../icons/IngresadoIcon';
import EnCaminoIcon from '../../icons/EnCaminoIcon';
import EntregadoIcon from '../../icons/EntregadoIcon';

const { Step } = Steps;

const HeaderSteps = (props) => {

  let title = `El envío ya fue entregado!`;
  let status = `success`;
  return (
    <>
      {props.data.estadoActual === 'Entregado' ? <Result status={status} title={title} /> : null}
      <Typography.Title style={{ marginBottom: 20 }} level={5}>
        Estado del Envío
      </Typography.Title>
      <Steps status="wait" current={1} labelPlacement="vertical">
        <Step
          status={props.data.fechaIngreso == null ? 'wait' : 'finish'}
          title="Ingreso"
          description={props.data.fechaIngreso}
          icon={props.data.fechaIngreso == null ? <LoadingOutlined /> : <IngresadoIcon />}
        />
        <Step
          status={props.data.fechaRetiro == null ? 'wait' : 'finish'}
          title="Retiro"
          description={props.data.fechaRetiro}
          icon={props.data.fechaRetiro == null ? <LoadingOutlined /> : <RetiradoIcon />} />
        <Step
          status={props.data.fechaEnCamino == null ? 'wait' : 'finish'}
          title="En Camino"
          description={props.data.fechaEnCamino}
          icon={props.data.fechaEnCamino == null ? <LoadingOutlined /> : <EnCaminoIcon />} />
        <Step
          status={props.data.fechaEntrega == null ? 'wait' : 'finish'}
          title="Entrega"
          description={props.data.fechaEntrega}
          icon={props.data.fechaEntrega == null ? <LoadingOutlined /> : <EntregadoIcon />} />
      </Steps>
    </>
  );
};

export default HeaderSteps;

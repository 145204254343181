
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFoundPage = () =>
  <>
    <Result status={404} title={404} subTitle='El servicio no existe.' extra={<Link to="/"><Button type="primary">Volver</Button></Link>} />
  </>

export default NotFoundPage;

import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import HomePage from './components/HomePage';
import StatusPage from './components/StatusPage';
import StatusDetailPage from './components/StatusDetailPage';
import NotFound from './components/NotFound';
const App = () =>
  <Router>
    <AppLayout>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/status/:qr" component={StatusPage} />
        <Route exact path="/detail/:serviceId/:qr" component={StatusDetailPage} />
        <Route component={NotFound} />
      </Switch>
    </AppLayout>
  </Router>

export default App;
